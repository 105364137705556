/*
 * This file is part of Universal Media Server, based on PS3 Media Server.
 *
 * This program is free software; you can redistribute it and/or
 * under the terms of the GNU General Public License as published by the Free
 * Software Foundation; version 2 of the License only.
 *
 * This program is distributed in the hope that it will be useful, but WITHOUT
 * ANY WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS
 * FOR A PARTICULAR PURPOSE. See the GNU General Public License for more
 * details.
 *
 * You should have received a copy of the GNU General Public License along with
 * this program; if not, write to the Free Software Foundation, Inc., 51
 * Franklin Street, Fifth Floor, Boston, MA 02110-1301, USA.
 */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: 100%;
}

.bodyBackgroundImageScreen {
	height: 100%;
	width: 100%;
	background-color: rgba(33, 33, 33, 1);
	transition: background-color .5s ease-in-out;
	-moz-transition: background-color .5s ease-in-out;
	-webkit-transition: background-color .5s ease-in-out; 
	-o-transition: background-color .5s ease-in-out; 
}

.backgroundPreloadContainer {
	display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.media-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  column-gap: 20px;
}

.thumbnail-container {
  text-align: center;
  background-color: var(--mantine-color-default);
  color: var(--mantine-color-text);
  cursor: pointer;
  height: 220px;
  margin-bottom: 20px;
}

.front-page-grid {
  height: 240px;
  overflow-y: hidden;
}

.thumbnail-container:hover {
  background-color: var(--mantine-color-default-hover);
  color: var(--mantine-color-bright);
}

nav button .mantine-Button-inner {
  justify-content: normal;
  font-weight: 400;
  color: var(--mantine-color-text);
}

nav button .mantine-Button-inner:hover {
  font-weight:600;
  color: var(--mantine-color-bright);
}

.mantine-Breadcrumbs-root .mantine-Button-inner {
  color: var(--mantine-color-text);
}

.mantine-Breadcrumbs-root .mantine-Button-inner:hover {
  color: var(--mantine-color-bright);
}
